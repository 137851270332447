<template>
  <crud-form
    :mostrar-botao-info="true"
    :tabs-cabecalho="tabCabecalhos"
    :titulo-pagina-tab="tituloPagina"
    :tab-selecionada.sync="tab"

    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
    @save-continue-event="saveContinueEvent"
    @mostrarInfo="updateHintStatus"
  >
    <v-tabs-items v-model="tab">
      <v-tab-item :value="'tab-dados-gerais'">
        <v-row>
          <v-col
            v-if="!idEmpresaCliente || idEmpresaCliente < 0"
            cols="12"
          >
            <empresa-cliente-auto-complete
              v-model="empresaClienteModel"
              :readonly="!!empresaClienteModelId || isEditting"
              :clearable="!isEditting"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="objEditar.nomeItem"
              :label="$t('ItemForm.nomeItem')"
              class="purple-input"
              prepend-icon="mdi-account-edit"
              :hint="showHint ? '${item.nomeItem}' : undefined"
              persistent-hint
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="objEditar.valorVenda"
              :label="$t('ItemForm.valorVenda')"
              class="purple-input"
              prepend-icon="mdi-currency-usd"
              type="number"
              :hint="showHint ? '${item.valorVenda}' : undefined"
              persistent-hint
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="objEditar.valorCusto"
              :label="$t('ItemForm.valorCusto')"
              class="purple-input"
              prepend-icon="mdi-coin-outline"
              type="number"
              :hint="showHint ? '${item.valorCusto}' : undefined"
              persistent-hint
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="objEditar.valorReposicao"
              :label="$t('ItemForm.valorReposicao')"
              class="purple-input"
              prepend-icon="mdi-coin-outline"
              type="number"
              :hint="showHint ? '${item.valorReposicao}' : undefined"
              persistent-hint
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-switch
              v-model="objEditar.controlaEstoque"
              :label="$t('ItemForm.controlaEstoque')"
              :messages="[$t('ItemForm.controlaEstoqueInfo')]"
              class="purple-input"
              prepend-icon="mdi-playlist-check"
            >
              <template v-slot:message="{key, message}">
                <i
                  style="color: orange"
                  class="mdi mdi-help-circle-outline mdi-20px"
                />
                <span style="color: orange">
                  {{ message }}
                </span>
              </template>
            </v-switch>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-switch
              v-model="objEditar.itemDescartavel"
              :label="$t('ItemForm.itemDescartavel')"
              :messages="[$t('ItemForm.itemDescartavelInfo')]"
              class="purple-input"
              prepend-icon="mdi-puzzle-check-outline"
            >
              <template v-slot:message="{key, message}">
                <i
                  style="color: orange"
                  class="mdi mdi-help-circle-outline mdi-20px"
                />
                <span style="color: orange">
                  {{ message }}
                </span>
              </template>
            </v-switch>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-switch
              v-model="objEditar.escondeVisaoCliente"
              :label="$t('ItemForm.escondeVisaoCliente')"
              :messages="[$t('ItemForm.escondeVisaoClienteInfo')]"
              class="purple-input"
              :prepend-icon="objEditar.escondeVisaoCliente? 'mdi-eye-off' : 'mdi-eye'"
            >
              <template v-slot:message="{key, message}">
                <i
                  style="color: orange"
                  class="mdi mdi-help-circle-outline mdi-20px"
                />
                <span style="color: orange">
                  {{ message }}
                </span>
              </template>
            </v-switch>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-switch
              v-model="objEditar.escondeVisaoExpedicao"
              :label="$t('ItemForm.escondeVisaoExpedicao')"
              :messages="[$t('ItemForm.escondeVisaoExpedicaoInfo')]"
              class="purple-input"
              :prepend-icon="objEditar.escondeVisaoExpedicao? 'mdi-eye-off' : 'mdi-eye'"
            >
              <template v-slot:message="{key, message}">
                <i
                  style="color: orange"
                  class="mdi mdi-help-circle-outline mdi-20px"
                />
                <span style="color: orange">
                  {{ message }}
                </span>
              </template>
            </v-switch>
          </v-col>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="objEditar.quantidadeEstoqueSeguranca"
              :label="$t('ItemForm.quantidadeEstoqueSeguranca')"
              class="purple-input"
              type="number"
              prepend-icon="mdi-order-bool-descending"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'tab-imagens'">
        <imagem
          :lista-imagens="listIdImagens"
          @imagem-salva="imagemSalva"
          @imagem-removida="imagemRemovida"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        aaaaa
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <span
          v-if="tab !== 'tab-dados-gerais'"
          slot="botoes"
        />
      </v-col>
    </v-row>
  </crud-form>
</template>

<script>

  import ItemService from '@/services/ItemService'
  import { mapState } from 'vuex'
  import EmpresaClienteAutoComplete from '@/views/EmpresaClienteAutoComplete'
  import ImagemService from '@/services/ImagemService'
  import Imagem from './Imagem'
  import hint from '@/mixins/hint'

  export default {
    components: {
      EmpresaClienteAutoComplete,
      Imagem
    },
    mixins: [hint],
    data: () => ({
      empresaClienteModel: null,
      empresaClienteModelId: null,
      mensagemPerguntaRemocao: 'Deseja remover este Item?',
      objPadrao: {
        nomeItem: '',
        valorVenda: 0,
        valorCusto: 0,
        controlaEstoque: true,
        itemDescartavel: false,
        quantidadeEstoque: 1,
        idEmpresaCliente: null,
        qtdDiaBloqAntEvt: 0,
        qtdDiaBloqApoEvt: 0,
        escondeVisaoCliente: false,
        escondeVisaoExpedicao: false,
        imagens: '',
        imagensArr: []
      },
      objEditar: {
        nomeItem: '',
        valorVenda: 0,
        valorCusto: 0,
        controlaEstoque: true,
        itemDescartavel: false,
        quantidadeEstoque: 1,
        idEmpresaCliente: null,
        qtdDiaBloqAntEvt: 0,
        qtdDiaBloqApoEvt: 0,
        escondeVisaoCliente: false,
        escondeVisaoExpedicao: false,
        imagens: ''
      },
      tab: null,
      isEditting: true,
      editandoImagem: false,
      idImagem: null,
      dialogoImagem: false,
      loadingDialog: false,
      imagens: null,
      listIdImagens: [],
      tabCabecalhos: [],
    }),
    computed: {
      ...mapState(['idEmpresaCliente']),
      tituloPagina () {
        return this.$i18n.t('ItemForm.titulo')
      },
      tabCabecalhosValendo () {
        var tabValendo = this.tabCabecalhos.filter((tab) => {
          return tab.mostraCabecalho
        })
        return tabValendo
      },
    },
    watch: {
      empresaClienteModel (val) {
        if (val === null || !val) {
          this.objEditar.EmpresaCliente = null
          this.objEditar.idEmpresaCliente = null
        } else {
          this.objEditar.EmpresaCliente = val
          this.objEditar.idEmpresaCliente = val.id
        }
      }

    },
    created () {
      this.$root.$emit('alteraMostraPesquisa', false)

      this.tabCabecalhos.push({
        texto: this.$i18n.t('padrao_dados_gerais'),
        link: '#tab-dados-gerais',
        mostraCabecalho: true
      }
      )

      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao
      }
    },
    methods: {
      saveContinueEvent () {
        if (this.idEmpresaCliente && this.idEmpresaCliente > 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        this.inserir(this.objEditar, true)
      },
      saveEventHandler () {
        if (this.idEmpresaCliente && this.idEmpresaCliente > 0) {
          this.objEditar.idEmpresaCliente = this.idEmpresaCliente
        }
        if (this.isEditting) {
          this.atualizar(this.objEditar, false)
        } else {
          this.inserir(this.objEditar, false)
        }
      },
      atualizar (item, manterNestaTela) {
        try {
          ItemService.update(item)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                if(!manterNestaTela){
                  this.$root.$emit('mostra_dialogo_info', {
                    dialogoTitulo: this.$t('padrao_sucesso'),
                    dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
                  })
                  this.closeDialog()
                }
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Não foi possível concluir a atualização, tente mais tarde\n' + error
          })
        }
      },
      closeDialog () {
        this.$router.push('/itens')
      },
      remover () {
        const item = this.objEditar
        try {
          ItemService.delete(item)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: 'Item removido com sucesso'
              })
              this.closeDialog()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover um Item:\n ->' + error
          })
        }
      },
      inserir (item, manterNestaTela) {
        try {
          ItemService.insert(item).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: this.$t('padrao_sucesso'),
                dialogoDescricao: this.$t('padrao_registro_salvo_sucesso')
              })
              if (manterNestaTela) {
                this.editar(item.data.id)
              } else {
                this.closeDialog()
              }
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um Item:\n ->' + error
          })
        }
      },
      editar (id) {
        this.imagens = []
        this.loadingImagem = true
        ItemService.findById({ id })
          .then(item => {
            this.objEditar = item.data
            this.isEditting = true
            this.empresaClienteModel = this.objEditar.EmpresaCliente

            if (this.isEditting) {
              this.tabCabecalhos.push({
                texto: this.$i18n.t('padrao_imagens'),
                link: '#tab-imagens',
                mostraCabecalho: true
              })
              this.buscaImagens()
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      },
      // Tratamento de Imagens

      visualizaImagem (imagem) {
        this.$root.$emit('view-image', imagem.img)
      },
      adicionarImagem () {
        this.editandoImagem = false
        this.idImagem = 0
        this.dialogImagem = true
      },
      editarImagem (imagem) {
        this.editandoImagem = true
        this.idImagem = imagem.id
        this.dialogImagem = true
      },
      imagemSalva (idImagem) {
        this.loadingDialog = true
        if (!this.objEditar.imagensArr) {
          this.objEditar.imagensArr = []
        }
        this.objEditar.imagensArr.push(idImagem)
        this.objEditar.imagens = JSON.stringify(this.objEditar.imagensArr)
        if (this.objEditar.id) {
          this.atualizar(this.objEditar, true)
        } else {
          if (this.objEditar.imagensArr && this.objEditar.imagensArr.length > 0) {
            ImagemService.findByIds({
              ids: this.objEditar.imagensArr
            }, {
              rowsPerPage: 1000
            })
              .then(imagensBanco => {
                this.processaRetornoConsultaImagens(imagensBanco)
              })
              .catch(error => {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: this.$i18n.t('padrao_ocorreu_um_erro_ultima_operacao') + ':\n ->' + error
                })
              })
              .finally(() => {
                this.loadingDialog = false
              })
          }
        }
      },
      imagemRemovida (idImagem) {
        this.imagens = null
        if (this.objEditar.imagensArr && this.objEditar.imagensArr.indexOf(idImagem) >= 0) {
          this.objEditar.imagensArr.splice(this.objEditar.imagensArr.indexOf(idImagem), 1)
          this.objEditar.imagens = JSON.stringify(this.objEditar.imagensArr)
          if (this.objEditar.id) {
            this.atualizar(this.objEditar, false)
          }
        }
      },
      buscaImagens () {
        console.log('buscaImagens -> ', this.objEditar.imagens)
        if (this.objEditar.imagens && this.objEditar.imagens.length > 0) {
          this.objEditar.imagensArr = JSON.parse(this.objEditar.imagens)
          this.listIdImagens = this.objEditar.imagensArr
          console.log('buscaImagens -> ', this.listIdImagens)
        }
      },
      processaRetornoConsultaImagens (imagensBanco) {
        const imagensRetorno = imagensBanco.data
        this.imagens = []
        for (let i = 0; i < imagensRetorno.length; i++) {
          const imagemProcessar = imagensRetorno[i]
          const buffer = Buffer.from(imagemProcessar.img, 'base64')
          var myBase64 = window.btoa(ImagemService.Uint8ToString(buffer))
          imagemProcessar.img = 'data:image/*;base64,' + myBase64
          this.imagens.push(imagemProcessar)
        }
      },
    }
  }
</script>
<style>
.mdi-20px { font-size: 20px; }
.field-info  {
  padding: 8px;
  margin-left: 25px;
  border-radius: 4px;
  background-color: #66BB6A;
  font-size: 0.8rem;
  color: white;
}
</style>